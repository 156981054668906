

































































































































































































































































































































import gql from 'graphql-tag';
import _ from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Wallet, WalletFlags } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiModal from '@/components/ui/UiModal.vue';
import { WalletService } from '@/services/walletService';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../config';
import { ApiSvcSubsidiary, ApiSvcWalletResponseDTO, OrganizationsApi, WalletsApi } from '../../../generated/api-svc';
import Connections from '../../components/org/Connections.vue';
import { getSymbolForCoin, getSymbolForCurrency } from '../../utils/coinUtils';
import Receive from '../Receive.vue';
import Send from '../Send.vue';
import UiPagination from '../ui/UiPagination.vue';
import UiSelect from '../ui/UiSelect.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';
import UiToggle from '../ui/UiToggle.vue';
import UiTooltip from '../ui/UiTooltip.vue';
import UiTruncateText from '../ui/UiTruncateText.vue';
import EnableWalletForBulk from './EnableWalletForBulk.vue';
import ViewWallet from './ViewWallet.vue';

interface GroupedRow extends Wallet {
  isGroupRow: boolean;
  walletRoleId?: string;
  walletRole?: string;
  disabled?: boolean; // its not on wallet type but should be.
}
interface WalletView extends Wallet {
  subsidiaryName?: string;
}

@Component({
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    Connections,
    UiDataTable,
    UiButton,
    UiCheckbox,
    UiToggle,
    UiModal,
    UiTruncateText,
    UiSelect,
    UiTooltip,
    UiPagination,
    UiTextEdit,
    EnableWalletForBulk,
    Send,
    Receive,
    ViewWallet,
    UiSelect2,
  },
})
export default class WalletListNew extends BaseVue {
  @Prop({ default: false })
  public readonly short?: boolean;

  public wallets: Array<GroupedRow> = [];
  public hoveredWallet: Wallet | null = null;
  public isLoading = 0;
  public isLoadingBalance = 0;
  public viewWallet: WalletView | null = null;
  public viewDialog = false;
  public dialogWidth = '500px';
  public sort: { id: string; asc: boolean } = { id: '', asc: false };
  public expandedWallet = '';
  public page = 1;
  public itemsPerPage = 10;
  public numFormat = numberUtils.format;
  public connections = [];
  public subs: ApiSvcSubsidiary[] = [];
  public walletRoles: { id: string; name: string }[] | undefined = [];
  public showDisabledWallets = false;
  public searchWallet = '';
  public filteredWallets: GroupedRow[] = [];
  public showTimestamp = false;
  public subsidiaryIds: string[] = [];

  public onSort(sort: { id: string; asc: boolean }) {
    this.sort.id = sort.id;
    this.sort.asc = sort.asc;
  }

  public get connectionIdToProviderMap(): { [key: string]: string } {
    return this.connections.reduce((map: { [key: string]: string }, connection: any) => {
      map[connection.id] = connection.provider;
      return map;
    }, {});
  }

  public get walletItems() {
    let wallets = this.wallets as Array<GroupedRow>;
    let retVal = [] as Array<GroupedRow>;
    if (!this.showDisabledWallets) {
      wallets = wallets.filter((x) => !x.disabled);
    }

    wallets = wallets.map((item) => {
      const walletRoleId = item.walletRoleId;
      if (this.walletRoles?.some((wr) => wr.id === walletRoleId)) {
        item.walletRole = this.walletRoles.find((wr) => wr.id === walletRoleId)?.name;
      } else {
        item.walletRole = 'Default';
      }

      return item;
    });

    if (this.sort.id === '') {
      const groupWallets = wallets.filter((x) => x.isGroupRow);
      const unGroupedWallets = wallets.filter((x) => !x.isGroupRow && !x.groupId);
      const groupedWallets = wallets.filter((x) => !x.isGroupRow && x.groupId);
      const groups = _.groupBy(groupedWallets, 'groupId');

      for (const wallet of unGroupedWallets) {
        retVal.push(wallet);
      }
      for (const key of Object.keys(groups)) {
        const groupWallet = groupWallets.find((x) => x.id === key);
        if (groupWallet) {
          retVal.push(groupWallet);
        }
        for (const wallet of groups[key]) {
          retVal.push(wallet);
        }
      }
    } else {
      wallets = wallets.filter((x) => !(x as GroupedRow).isGroupRow);
      retVal = wallets.sort((a: any, b: any) => {
        if (this.sort.id === 'balance') {
          return this.sort.asc ? this.balanceCheckCompare(a, b) : this.balanceCheckCompare(b, a);
        }
        if (this.sort.id === 'status' || this.sort.id === 'lastSync') {
          return this.sort.asc ? this.syncStatusCompare(a, b) : this.syncStatusCompare(b, a);
        }
        if (this.sort.id === 'syncToggle') {
          return this.sort.asc ? this.syncToggleCompare(a, b) : this.syncToggleCompare(b, a);
        }
        if (a[this.sort.id] === b[this.sort.id]) {
          return 0;
        } else {
          return this.sort.asc
            ? a[this.sort.id] < b[this.sort.id]
              ? -1
              : 1
            : a[this.sort.id] > b[this.sort.id]
            ? -1
            : 1;
        }
      });
    }

    // load cached wallets
    this.filteredWallets = retVal;
    return retVal;
  }

  public get pageWallets() {
    if (this.searchWallet.length) {
      const subsIds =
        this.subs
          ?.filter((x) => x.name?.toLowerCase().includes(this.searchWallet.toLowerCase()))
          ?.map((sub) => sub.id) ?? [];

      const roleIds =
        this.walletRoles
          ?.filter((x) => x.name?.toLowerCase().includes(this.searchWallet.toLowerCase()))
          ?.map((role) => role.id) ?? [];

      console.log('wallets', this.filteredWallets);

      return this.filteredWallets
        .filter((wallet: GroupedRow) => {
          const searchValue = this.searchWallet.toLowerCase();
          return (
            wallet.name?.toLowerCase().includes(searchValue) ||
            wallet.id?.toLowerCase().includes(searchValue) ||
            [wallet.addresses ?? []].filter((address) =>
              String(address ?? '')
                .toLowerCase()
                .includes(searchValue)
            ).length > 0 ||
            (subsIds.length > 0 && subsIds.includes(wallet.subsidiaryId ?? '')) ||
            (roleIds.length > 0 && roleIds.includes(wallet.walletRoleId ?? ''))
          );
        })
        .slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    } else {
      return this.walletItems.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    }
  }

  getSubName(id: string) {
    return this.subs?.find((x) => x.id === id)?.name ?? '';
  }

  balanceCheckCompare(a: any, b: any) {
    let retVal = 0;
    const aSuccess = a.latestBalanceCheck?.metaSuccess;
    const adate = a.lastSuccessfulBalanceCheckSEC;
    const bSuccess = b.latestBalanceCheck?.metaSuccess;
    const bdate = b.lastSuccessfulBalanceCheckSEC;
    if (aSuccess === bSuccess) {
      if (adate === bdate) {
        retVal = 0;
      }
      retVal = adate < bdate ? -1 : 1;
    } else if (aSuccess) {
      retVal = 1;
    } else {
      retVal = -1;
    }
    return retVal;
  }

  hasFiatValue(wallet: Wallet) {
    return wallet.balance?.balances?.some((b) => b?.fiatValue?.value);
  }

  totalFiatValue(wallet: Wallet) {
    return wallet.balance?.balances?.reduce((a: number, x) => {
      a += x?.fiatValue?.value ?? 0;
      return a;
    }, 0);
  }

  calcDeltaByTicker(wallet: any): [string, number][] {
    const results = wallet.latestBalanceCheck?.results || [];
    const groupedDict: { [id: string]: number } = {};
    for (const result of results) {
      if (result.lineDeltas) {
        for (const lineDelta of result.lineDeltas) {
          groupedDict[lineDelta.ticker] =
            (groupedDict[lineDelta.ticker] || 0) + (lineDelta.localValue - lineDelta.remoteValue);
        }
      }
    }
    return Object.entries(groupedDict);
  }

  onItemsPerPageChange(value: number) {
    this.itemsPerPage = value;
    this.page = 1;
  }

  syncStatusCompare(a: any, b: any) {
    let retVal = 0;
    if (a.lastSuccessfulSyncSEC === b.lastSuccessfulSyncSEC) {
      retVal = 0;
    } else retVal = a.lastSuccessfulSyncSEC < b.lastSuccessfulSyncSEC ? -1 : 1;
    return retVal;
  }

  syncToggleCompare(a: any, b: any) {
    let retVal = 0;
    const aVal = !a.isSyncEnabledSystem ? false : a.isSyncEnabledUser;
    const bVal = !b.isSyncEnabledSystem ? false : b.isSyncEnabledUser;
    if (aVal === bVal) {
      retVal = 0;
    } else retVal = aVal < bVal ? -1 : 1;
    return retVal;
  }

  public onRowHovered({ item, row, value }: { item: Wallet; row: HTMLElement; value: boolean }) {
    if (value && !(item as GroupedRow).isGroupRow) this.hoveredWallet = item;
    else if (!this.isLoadingBalance) this.hoveredWallet = null;
  }

  mounted() {
    if (this.checkFeatureFlag('default-categorization-fields')) this.dialogWidth = '80vw';
    // load wallet roles
    if (this.checkFeatureFlag('soda-report')) this.getWalletRoles();

    this.refresh();
    this.$root.$on('refresh-wallets', () => {
      this.refresh();
    });
  }

  public get features() {
    return this.$store.getters.features;
  }

  public get showBalance() {
    return this.checkFeatureFlag('wallets.balance', this.features);
  }

  public get pageRows() {
    if (this.short === false) {
      return [10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }];
    }

    return undefined;
  }

  public get showActions() {
    const actionWallet = _.find(this.wallets, (m) => m.type !== 1);
    if (actionWallet) {
      return true;
    } else {
      return false;
    }
  }

  public get headers() {
    const headers = [
      {
        id: 'name',
        label: this.$tc('_name', 2),
        defaultVisibility: true,
        defaultWidth: '157px',
        sortable: true,
      },
    ];
    if (!this.short) {
      headers.push({
        id: 'address',
        label: this.$tc('_address'),
        defaultVisibility: true,
        defaultWidth: '124px',
        sortable: false,
      });
    }
    if (this.checkFeatureFlag('wallets.balance', this.features)) {
      headers.push({
        id: 'token',
        label: 'Token',
        defaultVisibility: true,
        defaultWidth: '80px',
        sortable: false,
      });
      headers.push({
        id: 'amount',
        label: 'Amount',
        defaultVisibility: true,
        defaultWidth: '128px',
        sortable: false,
      });
      headers.push({
        id: 'fiatValue',
        label: 'Fiat Value',
        defaultVisibility: true,
        defaultWidth: '114px',
        sortable: false,
      });
      headers.push({
        id: 'providers',
        label: 'Blockchain / Exchange',
        defaultVisibility: true,
        defaultWidth: '114px',
        sortable: false,
      });
      headers.push({
        id: 'balanceTime',
        label: 'Balance Time',
        defaultVisibility: true,
        defaultWidth: '114px',
        sortable: false,
      });
      if (this.checkFeatureFlag('wallets.sync', this.features)) {
        headers.push({
          id: 'balance',
          label: 'Balance Check Result',
          defaultVisibility: true,
          defaultWidth: '162px',
          sortable: true,
        });
      }
      if (this.checkFeatureFlag('subsidiaries', this.features)) {
        headers.push({
          id: 'subsidiaryId',
          label: 'Subsidiary',
          defaultVisibility: true,
          defaultWidth: '100px',
          sortable: true,
        });
      }
      if (this.checkFeatureFlag('soda-report')) {
        headers.push({
          id: 'walletRoleId',
          label: this.$tc('_role'),
          defaultVisibility: true,
          defaultWidth: '100px',
          sortable: true,
        });
      }
    }
    if (!this.short && this.checkFeatureFlag('wallets.sync', this.features)) {
      headers.push({
        id: 'status',
        label: 'Sync Status',
        defaultVisibility: true,
        defaultWidth: '101px',
        sortable: true,
      });
      headers.push({
        id: 'lastSync',
        label: 'Last Sync',
        defaultVisibility: true,
        defaultWidth: '154px',
        sortable: true,
      });
      headers.push({
        id: 'syncToggle',
        label: 'Syncing',
        defaultVisibility: true,
        defaultWidth: '124px',
        sortable: true,
      });
    }

    return headers;
  }

  public getSymbolForCoin(coin: string) {
    return getSymbolForCoin(coin);
  }

  public getSymbolForCurrency(coin: string) {
    return getSymbolForCurrency(coin);
  }

  public async refresh() {
    await this.loadWallets();
    if (this.checkFeatureFlag('subsidiaries', this.features)) {
      this.isLoading++;
      try {
        const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await orgsApi.listSubsidiaries(this.orgId, { withCredentials: true });
        if (resp.status === 200) {
          this.subs = resp.data;
        } else {
          throw new Error('Problem loading subs');
        }
      } catch (e) {
        this.showErrorSnackbar('failed to load subsidiaries');
      } finally {
        this.isLoading--;
      }
    }
    this.onSort(this.sort);
  }

  public showWalletDetails(wallet: Wallet) {
    this.viewDialog = true;
    this.viewWallet = wallet;
    this.viewWallet.subsidiaryName = this.getSubName(wallet.subsidiaryId ?? '');
  }

  public closeDetails() {
    this.viewDialog = false;
    this.viewWallet = null;
  }

  public async toggleSync(wallet: any, value: boolean) {
    const api = new WalletsApi();
    await api.updateWallet(
      this.$store.state.currentOrg.id,
      wallet.id as string,
      { userSyncEnabled: wallet.isSyncEnabledUser },
      { withCredentials: true }
    );
    this.refresh();
  }

  public showExpand(id: string): boolean {
    const ex = this.$refs[id + '-amount'] as HTMLElement;
    if (!ex) return false;
    if (this.expandedWallet === id || ex.scrollHeight !== ex.clientHeight) {
      return true;
    } else {
      return false;
    }
  }

  public expand(id: string) {
    if (this.expandedWallet === id) {
      this.expandedWallet = '';
    } else {
      this.expandedWallet = id;
    }
  }

  public balanceCheckStatus(wallet: any): { message: string; state: string; icon: string } {
    const retVal = { message: '', state: 'error', icon: 'times-circle' };
    const oneDay = 60 * 60 * 24 * 1000;
    const checkDate = wallet?.lastSuccessfulBalanceCheckSEC;
    const isSuccess = wallet?.latestBalanceCheck?.metaSuccess;
    if (checkDate) {
      const diff = Date.now() - checkDate * 1000;
      const hoursDiff = Math.round(diff / 1000 / 60 / 60);
      if (hoursDiff < 24) {
        retVal.state = 'success';
        retVal.icon = 'check-circle';
        retVal.message = 'Success';
      } else {
        retVal.state = 'warning';
        retVal.icon = 'exclamation-triangle';
        retVal.message = 'Warning Outdated';
      }
    } else if (!isSuccess) {
      retVal.state = 'error';
      retVal.message = 'Error/NA';
    }
    return retVal;
  }

  public syncStatus(wallet: any): { message: string; state: string; icon: string } {
    const retVal = { message: 'Error/NA', state: 'error', icon: 'times-circle' };
    const syncDate = wallet?.lastSuccessfulSyncSEC;
    if (syncDate) {
      const diff = Date.now() - syncDate * 1000;
      const hoursDiff = Math.round(diff / 1000 / 60 / 60);
      if (hoursDiff < 24) {
        retVal.state = 'success';
        retVal.icon = 'check-circle';
        retVal.message = 'Success';
      } else {
        retVal.state = 'warning';
        retVal.icon = 'exclamation-triangle';
        retVal.message = 'Warning Outdated';
      }
    }
    return retVal;
  }

  public lastSyncTime(wallet: any): string {
    const syncDate = wallet?.lastSuccessfulSyncSEC;
    let retVal = 'Never';
    if (syncDate) {
      retVal = this.toPreferredDateTime(syncDate) || 'Never';
    }
    return retVal;
  }

  public linkScrolls(wallets: Wallet[]) {
    for (const wallet of wallets) {
      if (
        !this.$refs[wallet.id + '-amount'] ||
        (this.hasFiatValue(wallet) && !this.$refs[wallet.id + '-fiat']) ||
        !this.$refs[wallet.id + '-token']
      )
        continue;
      (this.$refs[wallet.id + '-amount'] as HTMLElement).addEventListener('scroll', () => {
        (this.$refs[wallet.id + '-token'] as HTMLElement).scrollTop = (
          this.$refs[wallet.id + '-amount'] as HTMLElement
        ).scrollTop;
        if (this.hasFiatValue(wallet))
          (this.$refs[wallet.id + '-fiat'] as HTMLElement).scrollTop = (
            this.$refs[wallet.id + '-amount'] as HTMLElement
          ).scrollTop;
      });
      (this.$refs[wallet.id + '-token'] as HTMLElement).addEventListener('scroll', () => {
        (this.$refs[wallet.id + '-amount'] as HTMLElement).scrollTop = (
          this.$refs[wallet.id + '-token'] as HTMLElement
        ).scrollTop;
        if (this.hasFiatValue(wallet))
          (this.$refs[wallet.id + '-fiat'] as HTMLElement).scrollTop = (
            this.$refs[wallet.id + '-token'] as HTMLElement
          ).scrollTop;
      });
      if (this.hasFiatValue(wallet))
        (this.$refs[wallet.id + '-fiat'] as HTMLElement).addEventListener('scroll', () => {
          (this.$refs[wallet.id + '-token'] as HTMLElement).scrollTop = (
            this.$refs[wallet.id + '-fiat'] as HTMLElement
          ).scrollTop;
          (this.$refs[wallet.id + '-amount'] as HTMLElement).scrollTop = (
            this.$refs[wallet.id + '-fiat'] as HTMLElement
          ).scrollTop;
        });
    }
  }

  public async getWalletRoles() {
    const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
    this.walletRoles = await walletSvc.getWalletRoles(this.$store.state.currentOrg.id);
  }

  public async loadWallets() {
    this.isLoading++;
    this.isLoadingBalance++;
    try {
      const apiSvc = new WalletsApi(undefined, baConfig.getFriendlyApiUrl());
      const options = { withCredentials: true };
      const resp = await apiSvc.getWallets(
        this.$store.state.currentOrg.id,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
        undefined,
        undefined,
        false, // excludeDisabled: bool - set false for parity with graphql call
        undefined,
        options
      );
      const wallets = resp.data.items.map((m) => this.fromWalletDto(m));
      this.wallets = wallets as GroupedRow[];
    } catch (error) {
      console.error('Error loading wallets:', error);
      this.showErrorSnackbar('Failed to load wallets. Error: ' + error);
    } finally {
      this.isLoadingBalance--;
      this.isLoading--;
    }
  }

  public fromWalletDto(wallet: ApiSvcWalletResponseDTO): Wallet {
    const value: Wallet & { root: boolean; leaf: boolean; expanded: boolean; children: any } = {
      ...wallet,
      flags: wallet.flags as WalletFlags,
      root: false,
      leaf: true,
      expanded: false,
      children: [],
    };
    return value;
  }

  public getBalanceTime(wallet: any): string {
    let balanceTimestamp;
    if (wallet.balance && wallet.balance.balances && wallet.balance.balances.length > 0) {
      balanceTimestamp = wallet.balance.balances.at(0).parentTimestamp;
    }
    return balanceTimestamp ? this.formatTimestamp(balanceTimestamp) : '';
  }

  public formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    return this.showTimestamp ? timestamp.toString() : date.toISOString();
  }

  public toggleTimestamp() {
    this.showTimestamp = !this.showTimestamp;
  }

  public get searchLabel() {
    let label = 'Search by name, id, address';

    if (this.hasSubsidiaries) {
      label += ', or by subsidiary';
    }

    if (this.hasRoleFilter) {
      label += ', or by role';
    }

    return label;
  }

  public get hasRoleFilter() {
    return this.checkFeatureFlag('soda-report');
  }

  public get hasSubsidiaries() {
    return this.checkFeatureFlag('subsidiaries', this.features);
  }

  public handleSubsidiaryChange(value: string[]) {
    console.log('handleSubsidiaryChange', value);
  }

  @Watch('page')
  public async onPageChanged() {
    await this.$nextTick();
    this.linkScrolls(this.pageWallets);
  }

  @Watch('wallets')
  public async onWalletsChange() {
    for (const wallet of this.wallets) {
      (wallet as GroupedRow).isGroupRow = wallet.type === 999;
    }
  }

  @Watch('$store.state.isUpdateWallet')
  updateWallet() {
    this.refresh();
  }
}
