var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-4 tw-flex tw-flex-col tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-items-end tw-gap-2"},[_c('div',[_c('ui-date-picker',{attrs:{"label":"From","max":_vm.endDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('div',[_c('ui-date-picker',{attrs:{"label":"To","min":_vm.startDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('div',{staticClass:"tw-flex tw-flex-grow tw-items-center tw-gap-2 tw-h-9"},[_c('ui-button',{attrs:{"disabled":_vm.isLoading},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.runReport.apply(null, arguments)}}},[_vm._v("Run Report")]),(_vm.reportElapsedTime)?_c('span',{staticClass:"tw-text-neutral-300 tw-text-xs"},[_vm._v(" Ran in "+_vm._s(_vm.reportElapsedTime)+" seconds ")]):_vm._e(),_c('ui-button',{staticClass:"tw-ml-auto",attrs:{"loading":_vm.csvLoading,"disabled":_vm.csvLoading,"color":"white"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.downloadReport.apply(null, arguments)}}},[_vm._v(" Download Report ")])],1)]),_c('div',{staticClass:"tw-w-full tw-rounded-lg tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-p-6 tw-mt-3"},[_c('ui-data-table',{staticClass:"tw-py-3",attrs:{"headers":_vm.headers,"items":_vm.lines,"isLoading":!!_vm.isLoading,"no-data-message":"There is no data to display."},scopedSlots:_vm._u([{key:"td-original_inventory",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[(line.original_inventory)?[_vm._v(" "+_vm._s(line.original_inventory)+" ")]:[_vm._v("-")]],2)])]}},{key:"td-original_wallet",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[(line.original_wallet)?[_vm._v(" "+_vm._s(line.original_wallet)+" ")]:[_vm._v("-")]],2)])]}},{key:"td-original_department",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[(line.original_department)?[_vm._v(" "+_vm._s(line.original_department)+" ")]:[_vm._v("-")]],2)])]}},{key:"td-asset",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[(line.asset)?[_vm._v(_vm._s(line.asset))]:[_vm._v("-")]],2)])]}},{key:"td-lotID",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-truncate",staticStyle:{"max-width":"100px"}},[(line.lotID)?[_vm._v(_vm._s(line.lotID))]:[_vm._v("-")]],2)])]}},{key:"td-carryingvalue_beginning",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.carryingvalue_beginning, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-carryingvalue_ending",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.carryingvalue_ending, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-units_acquired",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.units_acquired, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-units_disposed",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.units_disposed, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-units_beginning",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.units_beginning, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-units_ending",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.units_ending, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-costbasis_acquired",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.costbasis_acquired, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-costbasis_relieved",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.costbasis_relieved, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-impairExpense",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.impairExpense, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-impairmentReversal",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.impairmentReversal, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-revaluationAdjustmentUpward",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.revaluationAdjustmentUpward, { decimalPlaces: 4 }))+" ")])])]}},{key:"td-revaluationAdjustmentDownward",fn:function(ref){
var line = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(_vm.numFormat(line.revaluationAdjustmentDownward, { decimalPlaces: 4 }))+" ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }