






















































































































































































































































































































import gql from 'graphql-tag';
import { string } from 'mathjs';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { InventoryActionTypes } from '@/store/modules/inventories/types';

import { baConfig } from '../../../../config';
import {
  ApiSvcAssetValuationStrategies,
  ApiSvcGainLossSetupDTOTaxStrategyEnum,
  ApiSvcInventoryView,
  ApiSvcSpecificIdentificationCoinSpendingStrategy,
  ApiSvcSpecificIdentificationSelectionPreference,
  ApiSvcSpecificIdentificationSelectionStrategy,
  InventoryApi,
} from '../../../../generated/api-svc';
import {
  ApiSvcImpairmentMethodology,
  AssetValuationStrategiesType,
  AssetValuationStrategiesTypeConfig,
  impairmentMethodologiesKeys,
  impairmentStrategies,
  InventoryViewHelper,
  taxMethods,
} from '../helpers/inventoryViewHelper';

@Component({
  components: {
    UiSelect,
    UiSelect2,
    UiDropdown,
    UiCheckbox,
    UiButton,
    UiTextEdit,
    UiLoading,
    UiModal,
  },
})
export default class InventoryViewSettings extends BaseVue {
  @Prop()
  public readonly currentView!: any;

  public isValidInput = false;
  public deleteName = '';
  public openDelete = false;
  public isDeleteing = false;
  public taxStrategy: ApiSvcGainLossSetupDTOTaxStrategyEnum = ApiSvcGainLossSetupDTOTaxStrategyEnum.Fifo;
  public defaultStrategy = ApiSvcAssetValuationStrategies.GaapFairValue;
  public expandAdvanced = false;
  public expandBasic = true;

  loading = false;
  isEditable = false;
  editable = false;

  private actionButtons = {
    edit: {
      text: 'Edit View',
      icon: 'fa fa-pencil',
      color: 'primary',
    },
    save: {
      text: 'Save View',
      icon: 'fa fa-save',
      color: 'success',
    },
  };

  actionButton = this.actionButtons.edit;
  assetValuationStrategies: AssetValuationStrategiesType[] = [];
  assetValuationStrategiesConfig: AssetValuationStrategiesTypeConfig[] = [];

  // watch for changes in the current view and update the isEditable flag
  @Watch('currentView', { immediate: true })
  public onCurrentViewChange() {
    if (
      this.currentViewSafe.inventoryConfig &&
      this.currentViewSafe.inventoryConfig.assetValuationStrategies &&
      this.currentViewSafe.inventoryConfig.engineVersion >= 2
    ) {
      this.isEditable = true;
    } else {
      this.isEditable = false;
    }
  }

  getStrategy(key: string) {
    if (!key) return '';
    return impairmentStrategies[key as keyof typeof impairmentStrategies];
  }

  getStrategies(list: { ticker: string; assetValuationStrategy: string }[]) {
    if (!list?.length) return [];

    // load the strategies
    this.assetValuationStrategies = list.map((x) => ({
      coin: x.ticker,
      strategy: x.assetValuationStrategy as ApiSvcAssetValuationStrategies,
    }));

    return list.map((x) => `${x.ticker} (${this.getStrategy(x.assetValuationStrategy)})`).join(', ');
  }

  public validate(valid: boolean) {
    this.isValidInput = this.currentView.name === this.deleteName;
  }

  public closeDelete() {
    this.openDelete = false;
    this.deleteName = '';
  }

  friendlyImpairment(val: ApiSvcImpairmentMethodology) {
    return impairmentMethodologiesKeys[val] ?? 'Unknown Method';
  }

  public get mapSpendingStrategy(): { spendingStrategy: string; selectionPreference: string }[] {
    return (
      this.currentView.inventoryPickingStrategy?.specificIdentificationStrategyRanges[0]
        ?.identificationStrategyRanked as ApiSvcSpecificIdentificationSelectionStrategy[]
    ).map((s) => {
      let spending: string;
      let preference: string;
      switch (s.spendingStrategy) {
        case ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_1:
          spending = 'Short Term Gain';
          break;
        case ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_3:
          spending = 'Long Term Gain';
          break;
        case ApiSvcSpecificIdentificationCoinSpendingStrategy.NUMBER_2:
          spending = 'Short Term Loss';
          break;
        default:
          spending = 'Long Term Loss';
          break;
      }
      switch (s.selectionPreference) {
        case ApiSvcSpecificIdentificationSelectionPreference.NUMBER_1:
          preference = 'Minimize';
          break;
        default:
          preference = 'Maximize';
          break;
      }
      return {
        spendingStrategy: spending,
        selectionPreference: preference,
      };
    });
  }

  get inventoryGroups() {
    return this.$store.getters['inventories/INVENTORY_GROUPS'];
  }

  get inventoryGroupName() {
    return (
      this.inventoryGroups.find(
        (x: any) => x.id === this.currentViewSafe?.inventoryConfig?.inventoryMappingRule?.inventoryMappingId
      )?.name ?? 'None'
    );
  }

  get currentViewSafe(): any {
    const retVal = { ...this.currentView };
    if (!retVal.inventoryPickingStrategy) {
      retVal.inventoryPickingStrategy = {};
    }
    if (!retVal.inventoryConfig) {
      retVal.inventoryConfig = {};
    }
    if (!retVal.inventoryConfig.inventoryMappingRule) {
      retVal.inventoryConfig.inventoryMappingRule = {};
    }
    return retVal;
  }

  public async deleteView() {
    this.isDeleteing = true;
    this.openDelete = false;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());

      const resp = await svc.deleteView(orgId, this.currentView.id ?? '', {
        withCredentials: true,
      });

      if (resp.status !== 204) {
        throw new Error('Failed to delete view');
      }

      this.$emit('refresh-views');
    } finally {
      this.isDeleteing = false;
    }
  }

  async refreshInventoriesAndGroups() {
    return await this.$store.dispatch('inventories/' + InventoryActionTypes.GET_INVENTORIES, {
      orgId: this.$store.state.currentOrg.id,
    });
  }

  async mounted() {
    await this.refreshInventoriesAndGroups();
  }

  async resetActions() {
    this.editable = false;
    this.actionButton = this.actionButtons.edit;
  }

  public get valuationStrategies() {
    if (this.checkFeatureFlag('mark-to-market-no-fmv-rollback')) {
      return InventoryViewHelper.valuationStrategiesByFeatureFlag('mark-to-market-no-fmv-rollback');
    } else if (this.checkFeatureFlag('impairment')) {
      return InventoryViewHelper.valuationStrategiesByFeatureFlag('impairment');
    } else {
      return InventoryViewHelper.valuationStrategiesByFeatureFlag('default');
    }
  }

  public getPickingStrategy(type: number): string {
    return taxMethods[type as keyof typeof taxMethods];
  }

  private async validateTickers() {
    // Check if the tickers are valid
    const resp = await new InventoryViewHelper().validateTickers(this.assetValuationStrategies);
    if (resp && !resp.status) {
      this.showErrorSnackbar(resp.msg);
      return false;
    }

    if (resp.data) {
      this.assetValuationStrategiesConfig = resp.data;
    }

    return true;
  }

  private async updateViewSettings(inventoryViewSettings: ApiSvcInventoryView) {
    // update by using the GraphQL API
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      inventoryId: this.currentView.id,
      inventoryConfig: {
        assetValuationStrategies: this.assetValuationStrategiesConfig,
      },
    };

    // updateInventoryConfig
    return await this.$apollo.mutate({
      mutation: gql`
        mutation ($orgId: ID!, $inventoryId: ID!, $inventoryConfig: InventoryViewConfigInput!) {
          updateInventoryViewConfig(orgId: $orgId, inventoryId: $inventoryId, inventoryConfig: $inventoryConfig) {
            success
            errors
          }
        }
      `,
      variables: vars,
    });
  }

  async submitChange(event: Event) {
    event.preventDefault();

    if (this.actionButton.text === 'Edit View') {
      this.actionButton = {
        ...this.actionButtons.save,
      };

      this.editable = true;
    } else {
      // simulate save of view
      this.loading = true;
      this.actionButton.text = 'Saving...';

      const validationResp = await this.validateTickers();
      if (!validationResp) {
        this.loading = false;
        this.actionButton.text = 'Save View'; // reset state
        return;
      }

      // update in real time
      this.currentViewSafe.inventoryConfig.assetValuationStrategies = this.assetValuationStrategies.map((x) => ({
        ticker: x.coin,
        assetValuationStrategy: x.strategy,
      }));

      // call mutation to update view settings
      const resp = (await this.updateViewSettings(this.currentViewSafe)).data;
      if (resp.updateInventoryViewConfig.errors.length > 0) {
        this.showErrorSnackbar('Failed to save view');
        this.loading = false;
        this.actionButton.text = 'Save View'; // reset state
        return;
      }

      setTimeout(() => {
        this.actionButton = {
          ...this.actionButtons.edit,
        };

        this.editable = false;
        this.loading = false;
        this.showSuccessSnackbar(`View saved successfully [${this.currentViewSafe.name}]`);
      }, 500);
    }
  }
}
