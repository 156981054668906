import { store } from '../store';

function createDownloadIframe(): HTMLIFrameElement {
  const uniqueId = `temp-dl-iframe-${Date.now()}-${Math.random()}`;
  const iframe = document.createElement('iframe');
  iframe.id = uniqueId;
  iframe.name = uniqueId;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  return iframe;
}

export function clearDownloadIframes() {
  const existingIframes = document.querySelectorAll('iframe[id^="temp-dl-iframe-"]');
  existingIframes.forEach((iframe) => {
    if (document.body.contains(iframe)) {
      document.body.removeChild(iframe);
    }
  });
}

export function downloadAuthorizedFile(url: string) {
  const internalUrls = [process.env.VUE_APP_RPT_API_URL as string, process.env.VUE_APP_API_URL as string];
  const targetOrigin = new URL(url).origin;
  if (!store.state.authTokens || internalUrls.every((u) => new URL(u).origin !== targetOrigin)) {
    // external url, use iframe
    return downloadFile(url);
  }

  // create the form element
  const form = document.createElement('form');
  form.classList.add('temp-form');
  // set the action attribute of the form
  form.action = url;
  // set the method attribute of the form
  form.method = 'POST';
  // set the style of the form to display:none
  form.style.display = 'none';

  // add hidden field 'authorization'
  const authInput = document.createElement('input');
  authInput.type = 'hidden';
  authInput.name = 'authorization';
  authInput.value = 'Bearer ' + (store.state.authTokens?.accessToken.token ?? '');
  form.appendChild(authInput);

  // Create unique iframe for this download
  const iframe = createDownloadIframe();
  form.target = iframe.name;

  // append the form to the container element
  document.body.appendChild(form);

  try {
    // submit the form
    form.submit();
  } finally {
    // remove the form immediately after submission
    document.body.removeChild(form);
  }
}

export function downloadFile(url: string) {
  const iframe = createDownloadIframe();
  iframe.src = url;
}
