



























































































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';

import { baConfig } from '../../../../config';
import { ApiSvcInventoryView, ApiSvcInventoryViewUpdate, InventoryApi } from '../../../../generated/api-svc';
@Component({
  components: {
    CreateInventoryView,
    UiButton,
    WalletListNew,
    UiDataTable,
    UiTruncateText,
  },
})
export default class InventoryViewUpdates extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  public updates: ApiSvcInventoryViewUpdate[] = [];

  public baseHeaders = [
    {
      id: 'id',
      label: 'ID',
      defaultVisibility: true,
    },
    {
      id: 'runId',
      label: 'Run ID',
      defaultVisibility: true,
    },
    {
      id: 'started-by',
      label: 'Started By',
      defaultVisibility: true,
    },
    {
      id: 'started',
      label: 'Started',
      defaultVisibility: true,
    },
    {
      id: 'completed',
      label: 'Completed',
      defaultVisibility: true,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
  ];

  public lots: string[] = [];
  public isLoading = false;

  parseStatus(item: ApiSvcInventoryViewUpdate) {
    return item.status === 'Errored' ? `Errored: ${item.errors && item.errors[0]}` ?? 'Errored' : item.status;
  }

  public async mounted() {
    await this.loadUpdates();
  }

  public shouldShowCancelButton(update: ApiSvcInventoryViewUpdate) {
    return update.status === 'Running' || update.status === 'New';
  }

  public shouldShowIncrementalColumns() {
    return this.checkFeatureFlag('reference-inventory-view');
  }

  get computedHeaders() {
    let headers = [...this.baseHeaders];

    if (this.shouldShowIncrementalColumns()) {
      headers = [
        ...headers,
        { id: 'runIdReference', label: 'Reference Run ID', defaultVisibility: true },
        { id: 'startingSEC', label: 'Reference Run End Date', defaultVisibility: true },
        { id: 'endingSEC', label: 'Run End Date', defaultVisibility: true },
      ];
    }

    if (this.shouldShowActionsColumn()) {
      headers = [...headers, { id: 'actions', label: 'Actions', defaultVisibility: true }];
    }

    return headers;
  }

  public shouldShowActionsColumn() {
    return this.updates.some((update) => update.status === 'Running' || update.status === 'New');
  }

  public async loadUpdates() {
    if (this.view?.id) {
      this.isLoading = true;
      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const orgId = this.$store.state.currentOrg.id;
        const resp = await svc.getViewUpdates(orgId, this.view?.id, { withCredentials: true });
        if (resp.status === 200) {
          this.updates = resp.data.items;
        }
      } finally {
        this.isLoading = false;
      }
    }
  }

  public async cancelViewUpdate(updateId: string) {
    if (this.view?.id) {
      this.isLoading = true;
      try {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const orgId = this.$store.state.currentOrg.id;
        const resp = await svc.cancelViewUpdate(orgId, this.view?.id, updateId, { withCredentials: true });
        if (resp.status === 200) {
          if (!resp.data.success) this.showErrorSnackbar('Failed to cancel view');
          await this.loadUpdates();
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
}
