
















































































import moment from 'moment';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { downloadAuthorizedFile } from '@/utils/downloadFile';

import { baConfig } from '../../../../config';
import {
  ApiSvcInventoryReallocationReportReq,
  ApiSvcInventoryView,
  ApiSvcSortDirection,
  ApiSvcTaxStrategyType,
  InventoryApi,
} from '../../../../generated/api-svc';

@Component({
  components: {
    UiButton,
    UiTextEdit,
    UiDatePicker,
    UiLoading,
    UiSelect,
  },
})
export default class InventoryViewReallocation extends BaseVue {
  @Prop({ default: null })
  public readonly view!: ApiSvcInventoryView | null;

  public mode: 'automated' | 'import' = 'automated';
  public dataSourceId: string | null = '';
  public dataSources: string[] = ['test'];
  public isLoadingDataSource = false;
  public allViews: ApiSvcInventoryView[] = [];
  public walletSortOptions = [
    { value: ApiSvcSortDirection.Asc, label: 'Lowest to highest balance' },
    { value: ApiSvcSortDirection.Desc, label: 'Highest to lowest balance' },
  ];

  public lotsSortOptions = [
    { value: ApiSvcSortDirection.Asc, label: 'Earliest to latest acquired' },
    { value: ApiSvcSortDirection.Desc, label: 'Latest to earliest acquired' },
  ];

  public nonWliViews: { value: string; label: string }[] = [];
  public balanceOnlyViews: { value: string; label: string }[] = [];

  public lotBalanceTimestampSort: ApiSvcSortDirection = ApiSvcSortDirection.Asc;
  public walletBalanceSort: ApiSvcSortDirection = ApiSvcSortDirection.Asc;
  public originalInventoryViewId: string | null = null;
  public walletBalanceInventoryViewId: string | null = null;
  public endDate: string | null = null;
  public isValidating = false;
  public isValidated = false;
  public skipImportChecks = false;

  public disabled = false;
  public uploadData = '';
  public csvLoading = false;
  public lotsLoading = false;
  public walletBalancesLoading = false;
  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;

  downloadFile = downloadAuthorizedFile;

  async mounted() {
    this.isValidating = false;
    await this.loadInventoryViews();
  }

  onFileDrop(file: File) {
    return file;
  }

  public async runReallocation() {
    if (this.mode === 'automated') {
      if (this.view === null || this.view.id === null) {
        return;
      }

      const inventoryViewId = this.view.id;

      if (this.originalInventoryViewId === null) {
        return;
      }

      if (this.walletBalanceInventoryViewId === null) {
        return;
      }

      const originalInventoryViewId = this.originalInventoryViewId;
      const walletBalanceInventoryViewId = this.walletBalanceInventoryViewId;
      const endDate = this.endDate;
      const lotBalanceTimestampSort = this.lotBalanceTimestampSort;
      const walletBalanceSort = this.walletBalanceSort;

      const inventoryReallocationReportReq: ApiSvcInventoryReallocationReportReq = {
        originalInventoryViewId: originalInventoryViewId!,
        walletBalanceInventoryViewId: walletBalanceInventoryViewId!,
        endDate: endDate!,
        inventorySortFilter: {
          field: 'timestampSEC',
          direction: lotBalanceTimestampSort,
        },
        walletBalanceSortFilter: {
          field: 'balance',
          direction: walletBalanceSort,
        },
      };

      try {
        const orgId = this.$store.state.currentOrg.id;
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());

        await svc.triggerInventoryViewReallocation(orgId, inventoryViewId!, inventoryReallocationReportReq, {
          withCredentials: true,
        });

        this.$emit('navigate-to-updates');
      } catch (e) {
        console.log('problem updating view', e);
      }
    } else {
      // Manual mode
      if (this.originalInventoryViewId === null) {
        return;
      }

      if (this.dataSourceId === null) {
        return;
      }

      if (this.endDate === null) {
        return;
      }

      if (this.view === null || this.view.id === null) {
        return;
      }

      const inventoryReallocationReportReq: ApiSvcInventoryReallocationReportReq = {
        originalInventoryViewId: this.originalInventoryViewId,
        endDate: this.endDate,
        reallocationDataSourceId: this.dataSourceId,
        skipImportChecks: this.skipImportChecks,
      };

      try {
        const orgId = this.$store.state.currentOrg.id;
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());

        await svc.triggerInventoryViewReallocation(orgId, this.view.id!, inventoryReallocationReportReq, {
          withCredentials: true,
        });

        this.$emit('navigate-to-updates');
      } catch (e) {
        console.log('problem updating view', e);
      }
    }
  }

  filesChange(fileList: string[]) {
    if (fileList.length === 1) {
      this.uploadData = fileList[0];
    }
  }

  get isValidForReallocation() {
    if (this.mode === 'import') {
      return this.dataSourceId !== null && this.endDate !== null && this.originalInventoryViewId !== null;
    } else if (this.mode === 'automated') {
      return (
        this.originalInventoryViewId !== null && this.walletBalanceInventoryViewId !== null && this.endDate !== null
      );
    }
  }

  private async loadInventoryViews() {
    this.lotsLoading = true;
    try {
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const pageToken = undefined;

      const resp = await svc.getViews(this.orgId, pageToken, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        for (const view of resp.data.items) {
          const resp = await svc.getView(this.orgId, view.id, {
            withCredentials: true,
          });
          if (resp.status === 200) {
            this.allViews.push(resp.data.item);
          }
        }
      }

      const nonWliViews = this.allViews
        .filter((view) => view.inventoryConfig?.inventoryMappingRule === undefined)
        .map((view) => ({
          value: view.id!,
          label: view.name!,
        }));
      this.nonWliViews = nonWliViews;

      const balanceOnlyViews = this.allViews
        .filter(
          (view) =>
            view.inventoryPickingStrategy?.type === ApiSvcTaxStrategyType.NUMBER_6 &&
            view.inventoryConfig?.inventoryMappingRule !== undefined
        )
        .map((view) => ({
          value: view.id!,
          label: view.name!,
        }));

      this.balanceOnlyViews = balanceOnlyViews;
    } catch (e) {
      console.error('Error loading inventory views:', e);
    } finally {
      this.lotsLoading = false;
    }
  }

  // private async loadDataSources() {
  //   this.isLoadingDataSource = true;
  //   try {
  //     const ds = DataApiManager.getInstance();
  //     const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(this.orgId, undefined, undefined, {
  //       withCredentials: true,
  //     });
  //     if (resp.status === 200) {
  //       this.dataSources = (resp.data.items?.map(item => ({
  //         value: { id: item.id },
  //         label: item.name || item.id || 'Unknown Data Source'
  //       })) || []);
  //     } else {
  //       const e = new Error('Bad response: ' + resp.status);
  //       this.showErrorSnackbar((e as Error).message);
  //     }
  //   } finally {
  //     this.isLoadingDataSource = false;
  //   }
  // }

  private updateAsOfDate(date: Date) {
    this.endDate = moment(date).format('YYYY-MM-DD');
  }
}
