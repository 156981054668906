




























































































































































import { update } from 'lodash';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
import InventoryViewActions from '@/components/inventory/details/InventoryViewActions.vue';
import InventoryViewDashboard from '@/components/inventory/details/InventoryViewDashboard.vue';
import InventoryViewLots from '@/components/inventory/details/InventoryViewLots.vue';
import InventoryViewReallocation from '@/components/inventory/details/InventoryViewReallocation.vue';
import InventoryViewReports from '@/components/inventory/details/InventoryViewReports.vue';
import InventoryViewSettings from '@/components/inventory/details/InventoryViewSettings.vue';
import InventoryViewSpecId from '@/components/inventory/details/InventoryViewSpecID.vue';
import InventoryViewUpdates from '@/components/inventory/details/InventoryViewUpdates.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';

import { baConfig } from '../../../config';
import {
  ApiSvcInventoryView,
  ApiSvcInventoryViewUpdate,
  ApiSvcInventoryViewUpdateEnhancedReq,
  InventoryApi,
} from '../../../generated/api-svc';
import UiTooltip from '../ui/UiTooltip.vue';

@Component({
  components: {
    InventoryViewReports,
    InventoryViewDashboard,
    InventoryViewUpdates,
    InventoryViewLots,
    InventoryViewSpecId,
    CreateInventoryView,
    UiButton,
    WalletListNew,
    InventoryViewActions,
    InventoryViewSettings,
    InventoryViewReallocation,
    UiTooltip,
    UiTextEdit,
    UiDatePicker2,
    UiSelect2,
  },
})
export default class InventoryViewDetails extends BaseVue {
  @Prop({ default: null })
  public readonly inventoryViewId!: string | null;

  public view: ApiSvcInventoryView | null = null;
  public updates: ApiSvcInventoryViewUpdate[] = [];
  public isLoading = false;
  public isUpdatingView = false;
  public actionsDefaultFilter?: { [id: string]: Array<string | number> } | null = null;
  public actionsDefaultAsOf = '';
  public allowUpdate = true;
  public avaLabsBviInventoryViewId = 'jMkRegdyjHgvf34EzgaL';
  public avaLabsBviOrgId = 'acdxsokj3hC6MEbcN9Pa';
  public referenceRunId = '';
  public asOf = '';
  public endDate = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');
  public transferOptions = [
    { id: 'carryingValue', label: 'Carrying Value' },
    { id: 'historicalCost', label: 'Historical Cost' },
  ];

  public transferMethod = this.transferOptions[0];

  public selectedView = 'updates';

  public getTabClass(id: string) {
    if (id === this.selectedView) {
      return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    } else {
      return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    }
  }

  get isUpdateButtonDisabled(): boolean {
    const hasRunIdOnly = !!this.referenceRunId && !this.asOf;
    const hasAsOfOnly = !!this.asOf && !this.referenceRunId;
    return hasRunIdOnly || hasAsOfOnly;
  }

  get isAnyRunInProgress(): boolean {
    const inProgress = this.updates.some(
      (update) =>
        (update.status === 'Running' || update.status === 'New') && update.inventoryViewId === this.inventoryViewId
    );

    return inProgress;
  }

  get isAvaLabsBviBlockedView(): boolean {
    return this.inventoryViewId === this.avaLabsBviInventoryViewId && this.orgId === this.avaLabsBviOrgId;
  }

  get lockIcon(): string {
    return this.allowUpdate ? 'fa-unlock' : 'fa-lock';
  }

  @Watch('inventoryViewId')
  async watchInventoryViewId() {
    await this.loadInventoryView();
    this.navigateToTab();
  }

  async mounted() {
    await this.loadInventoryView();
    await this.loadUpdates();
    this.navigateToTab();
  }

  public async setView(newView: string) {
    this.selectedView = newView;
    await this.loadUpdates();
  }

  public async viewActions(options: { status: string; asOf: string }) {
    if (options.status) {
      this.actionsDefaultFilter = { status: [options.status] };
    } else {
      this.actionsDefaultFilter = null;
    }
    this.actionsDefaultAsOf = options.asOf;
    this.selectedView = 'actions';
    await this.$nextTick();
    this.actionsDefaultFilter = null;
    this.actionsDefaultAsOf = '';
  }

  private async loadInventoryView() {
    this.isLoading = true;
    try {
      if (this.inventoryViewId) {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await svc.getView(this.orgId, this.inventoryViewId, {
          withCredentials: true,
        });
        if (resp.status === 200) {
          this.view = resp.data.item;
          if (this.view.lastUpdateRunSEC) {
            this.selectedView = 'dashboard';
          } else {
            this.selectedView = 'updates';
          }
          if (this.view.allowUpdate !== undefined) this.allowUpdate = this.view.allowUpdate;
        }
      }
    } catch (e) {
    } finally {
      this.isLoading = false;
    }
  }

  public refreshViews() {
    this.$emit('refresh-views');
  }

  public async loadUpdates() {
    if (this.$refs.ivu) {
      try {
        await (this.$refs.ivu as InventoryViewUpdates).loadUpdates();
        if (this.$refs.ivu && (this.$refs.ivu as InventoryViewUpdates).updates) {
          this.updates = [...(this.$refs.ivu as InventoryViewUpdates).updates];
        }
      } catch (error) {
        console.error('Error loading updates:', error);
      }
    } else {
      console.warn('InventoryViewUpdates component is not available.');
    }
  }

  public async updateInventoryView() {
    if (this.inventoryViewId === null) {
      return;
    }

    this.isUpdatingView = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
      const req: ApiSvcInventoryViewUpdateEnhancedReq = {
        runIdReference: this.referenceRunId,
        startingDate: this.asOf,
        endingDate: this.endDate,
        transferAtHistoricalCost: this.transferMethod.id === 'historicalCost',
      };
      await svc.triggerViewUpdateEnhanced(orgId, this.inventoryViewId, req, {
        withCredentials: true,
      });

      await this.loadUpdates();
    } catch (e) {
      console.log('problem updating view', e);
    } finally {
      this.isUpdatingView = false;
    }
  }

  public navigateToTab() {
    const queryParams = this.$route.query;
    if (queryParams.view && typeof queryParams.view === 'string') {
      this.selectedView = queryParams.view;
    }
  }

  public async updateInventoryViewProperties(allowUpdate: boolean) {
    try {
      if (this.inventoryViewId) {
        const svc = new InventoryApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await svc.updateViewProperties(this.orgId, this.inventoryViewId, allowUpdate);
        if (resp.status === 200) {
          this.view = resp.data.item;
          this.allowUpdate = this.view.allowUpdate;
        } else {
          this.showErrorSnackbar('Failed to update view');
        }
      }
    } catch (e) {
      this.showErrorSnackbar('Failed to update view');
    }
  }

  private updateReferenceRunId(newValue: string) {
    this.referenceRunId = newValue;
  }

  private updateAsOfDate(date: Date) {
    const selectedDate = moment(date).format('YYYY-MM-DD');
    const maxSelectableDate = moment().subtract(1, 'day').format('YYYY-MM-DD');

    if (moment(selectedDate).isSameOrBefore(maxSelectableDate)) {
      this.asOf = moment(date).format('YYYY-MM-DD');
    } else {
      this.showErrorSnackbar('Reference Run End date cannot be today or a future date.');
    }
  }

  private updateEndDate(date: Date) {
    const selectedDate = moment(date).format('YYYY-MM-DD');
    const maxSelectableDate = moment().subtract(1, 'day').format('YYYY-MM-DD');

    if (moment(selectedDate).isSameOrBefore(maxSelectableDate)) {
      this.endDate = selectedDate;
    } else {
      this.endDate = maxSelectableDate;
      this.showErrorSnackbar('End date cannot be today or a future date.');
    }
  }
}
